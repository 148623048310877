
import { mapGetters } from 'vuex';
import getAllEvents from '../../queries/getAllEvents';
export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      events: null,
      eventsDisplayed: 3
    };
  },
  async fetch() {
    const query = getAllEvents(this.locale);
    this.events = await this.$sanity.fetch(query);
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale',
      isModalActive: 'ui/isModalActive'
    })
  },
  methods: {
    onClick() {
      this.eventsDisplayed += 3;
    }
  }
};
