import { render, staticRenderFns } from "./EventList.vue?vue&type=template&id=c7867aa0&"
import script from "./EventList.vue?vue&type=script&lang=js&"
export * from "./EventList.vue?vue&type=script&lang=js&"
import style0 from "./EventList.vue?vue&type=style&index=0&id=c7867aa0&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/vercel/path0/components/elements/Picture.vue').default,Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
