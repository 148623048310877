import { groq } from '@nuxtjs/sanity';

const getAllEvents = (locale) => groq`*[
  _type == "event" && 
  (!defined(startDate) || !defined(endDate) || dateTime(now()) <= dateTime(endDate)) && 
  __i18n_lang == "${locale}" && 
  !(_id in path("drafts.**"))
] | order(startDate asc)`;

export default getAllEvents;
